import React from 'react'
import BooksImg from './assets/books-53-img.png'
import style from './section53Img.module.less'

function Section53Img() {
  return (
    <div className={style.container}>
      <h2>与知名教辅全面战略合作，数千册精品教辅免费畅享</h2>
      <h3 style={{ marginLeft: '5vw', marginRight: '5vw' }}>
        曲一线旗下「5·3系列」、华东师大版「一课一练」、实验班「提优训练」等全面上线
      </h3>

      <div className={style.content}>
        <img src={BooksImg} alt="合作书籍" />
      </div>
    </div>
  )
}

export default React.memo(Section53Img)
