import React from 'react'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween, Timeline } from 'react-gsap'
import PaperImg from './assets/paper.png'
import PaperImg1 from './assets/paper1.png'
import PaperImg2 from './assets/paper2.png'
import PaperImg3 from './assets/paper3.png'
import PaperImg4 from './assets/paper4.png'
import PaperImg5 from './assets/paper5.png'
import PaperImg6 from './assets/paper6.png'
import PaperImg7 from './assets/paper7.png'
import PaperImg8 from './assets/paper8.png'
import PaperImg9 from './assets/paper9.png'
import PadImg from './assets/pad.png'
import './FindPaper.less'

const animationDuration = 200
const animationDelay = 200

export default function FindPaper({ windowLg }: { windowLg: boolean }) {
  const tweenPaper = {
    from: {
      css: {
        opacity: 1,
      },
    },
    to: {
      css: {
        transform: 'translate(-53.9%, 0)',
        top: '0',
        left: '0',
        height: '49%',
        bottom: '50%',
      },
    },
    toSm: {
      css: {
        transform: 'translate(0, 0)',
        top: '0',
        left: '10%',
        width: '49%',
        maxWidth: 'calc(0.43 * ( 100vh - 26.4vw ) )',
      },
    },
  }

  const tweenPapers = {
    from: {
      css: {
        opacity: 0,
      },
    },
    to: {
      css: {
        opacity: 1,
      },
    },
  }

  return (
    <>
      {windowLg ? (
        <section className="animate fd-root-lg">
          {/* @ts-ignore */}
          <Controller>
            <div>
              {/* @ts-ignore */}
              <Scene triggerHook="onLeave" offset={-64} duration="200%" pin>
                {(progress: any) => (
                  <div className="fd-main">
                    <h2 className="fd-title">找试卷，不费心</h2>
                    <h3>
                      百万真题试卷，最新真题超80%，期中期末提前测，准备充分有信心。
                    </h3>
                    <div className="fd-wrap">
                      <Timeline
                        target={
                          <img
                            className="fd-pad"
                            src={PadImg}
                            alt="小猿学练机"
                          />
                        }
                        totalProgress={progress}
                      >
                        <Tween
                          from={{ css: { opacity: 0 } }}
                          to={{ css: { opacity: 1 } }}
                          delay={animationDelay}
                          duration={animationDuration * 4}
                        />
                      </Timeline>

                      <Timeline
                        target={
                          <img
                            className="fd-paper-active"
                            src={PaperImg}
                            alt="试卷"
                          />
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{
                            translateX: '-50%',
                            translateY: '-50%',
                            scale: 1,
                          }}
                          to={{
                            translateX: '-95%',
                            translateY: '-75%',
                            scale: 0.5,
                          }}
                          duration={animationDuration}
                        />
                        <Tween
                          to={{
                            translateX: '-50%',
                            translateY: '-50%',

                            opacity: 0,
                            height: '49%',
                          }}
                          delay={animationDelay}
                          duration={animationDuration}
                        />
                      </Timeline>

                      <Timeline
                        target={
                          <img
                            className="fd-paper fd-paper-layer1 fd-paper-0"
                            src={PaperImg1}
                            alt="试卷"
                          />
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{
                            translateX: '-145%',
                            translateY: '-75%',
                            scale: 0.5,
                            opacity: 0,
                          }}
                          to={{
                            translateX: '-145%',
                            translateY: '-75%',
                            scale: 0.5,
                            opacity: 1,
                          }}
                          duration={animationDuration}
                        />
                        <Tween
                          to={{
                            css: {
                              translateX: '-50%',
                              translateY: '-50%',
                              opacity: 0,
                            },
                          }}
                          delay={animationDelay}
                          duration={animationDuration}
                        />
                      </Timeline>

                      <Timeline
                        target={
                          <img
                            className="fd-paper fd-paper-layer1 fd-paper-2"
                            src={PaperImg2}
                            alt="试卷"
                          />
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{
                            translateX: '-45%',
                            translateY: '-75%',
                            scale: 0.5,
                            opacity: 0,
                          }}
                          to={{
                            translateX: '-45%',
                            translateY: '-75%',
                            scale: 0.5,
                            opacity: 1,
                          }}
                          duration={animationDuration}
                        />
                        <Tween
                          to={{
                            translateX: '-50%',
                            translateY: '-50%',
                            opacity: 0,
                          }}
                          delay={animationDelay}
                          duration={animationDuration}
                        />
                      </Timeline>

                      <Timeline
                        target={
                          <img
                            className="fd-paper fd-paper-layer1 fd-paper-3"
                            src={PaperImg3}
                            alt="试卷"
                          />
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{
                            translateX: '5%',
                            translateY: '-75%',
                            scale: 0.5,
                            opacity: 0,
                          }}
                          to={{
                            translateX: '5%',
                            translateY: '-75%',
                            scale: 0.5,
                            opacity: 1,
                          }}
                          duration={animationDuration}
                        />
                        <Tween
                          to={{
                            translateX: '-50%',
                            translateY: '-50%',
                            opacity: 0,
                          }}
                          delay={animationDelay}
                          duration={animationDuration}
                        />
                      </Timeline>

                      <Timeline
                        target={
                          <img
                            className="fd-paper fd-paper-layer1 fd-paper-4"
                            src={PaperImg4}
                            alt="试卷"
                          />
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{
                            translateX: '55%',
                            translateY: '-75%',
                            scale: 0.5,
                            opacity: 0,
                          }}
                          to={{
                            translateX: '55%',
                            translateY: '-75%',
                            scale: 0.5,
                            opacity: 1,
                          }}
                          duration={animationDuration}
                        />
                        <Tween
                          to={{
                            translateX: '-50%',
                            translateY: '-50%',
                            opacity: 0,
                          }}
                          delay={animationDelay}
                          duration={animationDuration}
                        />
                      </Timeline>

                      <Timeline
                        target={
                          <img
                            className="fd-paper fd-paper-layer2 fd-paper-0"
                            src={PaperImg5}
                            alt="试卷"
                          />
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{
                            translateX: '-155%',
                            translateY: '-25%',
                            scale: 0.5,
                            opacity: 0,
                          }}
                          to={{
                            translateX: '-155%',
                            translateY: '-25%',
                            scale: 0.5,
                            opacity: 1,
                          }}
                          duration={animationDuration}
                        />
                        <Tween
                          to={{
                            translateX: '-50%',
                            translateY: '-50%',
                            opacity: 0,
                            scale: 0.5,
                          }}
                          delay={animationDelay}
                          duration={animationDuration}
                        />
                      </Timeline>

                      <Timeline
                        target={
                          <img
                            className="fd-paper fd-paper-layer2 fd-paper-1"
                            src={PaperImg6}
                            alt="试卷"
                          />
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{
                            translateX: '-105%',
                            translateY: '-25%',
                            scale: 0.5,
                            opacity: 0,
                          }}
                          to={{
                            translateX: '-105%',
                            translateY: '-25%',
                            scale: 0.5,
                            opacity: 1,
                          }}
                          duration={animationDuration}
                        />
                        <Tween
                          to={{
                            translateX: '-50%',
                            translateY: '-50%',
                            opacity: 0,
                            scale: 0.5,
                          }}
                          delay={animationDelay}
                          duration={animationDuration}
                        />
                      </Timeline>

                      <Timeline
                        target={
                          <img
                            className="fd-paper fd-paper-layer2 fd-paper-2"
                            src={PaperImg7}
                            alt="试卷"
                          />
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{
                            translateX: '-55%',
                            translateY: '-25%',
                            scale: 0.5,
                            opacity: 0,
                          }}
                          to={{
                            translateX: '-55%',
                            translateY: '-25%',
                            scale: 0.5,
                            opacity: 1,
                          }}
                          duration={animationDuration}
                        />
                        <Tween
                          to={{
                            translateX: '-50%',
                            translateY: '-50%',
                            opacity: 0,
                            scale: 0.5,
                          }}
                          delay={animationDelay}
                          duration={animationDuration}
                        />
                      </Timeline>

                      <Timeline
                        target={
                          <img
                            className="fd-paper fd-paper-layer2 fd-paper-3"
                            src={PaperImg8}
                            alt="试卷"
                          />
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{
                            translateX: '-5%',
                            translateY: '-25%',
                            scale: 0.5,
                            opacity: 0,
                          }}
                          to={{
                            translateX: '-5%',
                            translateY: '-25%',
                            scale: 0.5,
                            opacity: 1,
                          }}
                          duration={animationDuration}
                        />
                        <Tween
                          to={{
                            translateX: '-50%',
                            translateY: '-50%',
                            opacity: 0,
                            scale: 0.5,
                          }}
                          delay={animationDelay}
                          duration={animationDuration}
                        />
                      </Timeline>

                      <Timeline
                        target={
                          <img
                            className="fd-paper fd-paper-layer2 fd-paper-4"
                            src={PaperImg9}
                            alt="试卷"
                          />
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{
                            translateX: '45%',
                            translateY: '-25%',
                            scale: 0.5,
                            opacity: 0,
                          }}
                          to={{
                            translateX: '45%',
                            translateY: '-25%',
                            scale: 0.5,
                            opacity: 1,
                          }}
                          duration={animationDuration}
                        />
                        <Tween
                          to={{
                            translateX: '-50%',
                            translateY: '-50%',
                            opacity: 0,
                            scale: 0.5,
                          }}
                          delay={animationDelay}
                          duration={animationDuration}
                        />
                      </Timeline>
                    </div>
                  </div>
                )}
              </Scene>
            </div>
          </Controller>
        </section>
      ) : (
        <section className="animate fd-root-sm">
          {/* @ts-ignore */}
          <Controller>
            <div>
              {/* @ts-ignore */}
              <Scene triggerHook="onLeave" offset={-64} duration={500} pin>
                {(progress: any) => (
                  <div className="fd-main">
                    <h2>找试卷，不费心</h2>
                    <h3 style={{ marginLeft: '5vw', marginRight: '5vw' }}>
                      百万真题试卷，最新真题超80%，期中期末提前测，准备充分有信心。
                    </h3>
                    <div className="fd-wrap">
                      <Timeline
                        target={
                          <img
                            className="fd-pad"
                            src={PadImg}
                            alt="小猿学练机"
                          />
                        }
                        totalProgress={progress}
                      >
                        <Tween
                          from={{ css: { opacity: 0 } }}
                          to={{ css: { opacity: 1 } }}
                          delay={animationDelay}
                          duration={animationDuration * 4}
                        />
                      </Timeline>

                      <Timeline
                        target={
                          <img
                            className="fd-paper-active"
                            src={PaperImg}
                            alt="试卷"
                          />
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{
                            translateX: '-50%',
                            translateY: '-50%',
                            scale: 0.5,
                          }}
                          to={{
                            translateX: '-50%',
                            translateY: '-85%',
                            scale: 0.2,
                          }}
                          duration={animationDuration}
                        />
                        <Tween
                          to={{
                            translateX: '-50%',
                            translateY: '-50%',
                            opacity: 0,
                            scale: 0.2,
                          }}
                          delay={animationDelay}
                          duration={animationDuration}
                        />
                      </Timeline>

                      <Timeline
                        target={
                          <img
                            className="fd-paper fd-paper-layer1 fd-paper-0"
                            src={PaperImg1}
                            alt="试卷"
                          />
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{
                            translateX: '-70%',
                            translateY: '-85%',
                            scale: 0.2,
                            opacity: 0,
                          }}
                          to={{
                            translateX: '-70%',
                            translateY: '-85%',
                            scale: 0.2,
                            opacity: 1,
                          }}
                          duration={animationDuration}
                        />
                        <Tween
                          to={{
                            css: {
                              translateX: '-50%',
                              translateY: '-50%',
                              scale: 0.2,
                              opacity: 0,
                            },
                          }}
                          delay={animationDelay}
                          duration={animationDuration}
                        />
                      </Timeline>

                      <Timeline
                        target={
                          <img
                            className="fd-paper fd-paper-layer1 fd-paper-2"
                            src={PaperImg2}
                            alt="试卷"
                          />
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{
                            translateX: '-30%',
                            translateY: '-85%',
                            scale: 0.2,
                            opacity: 0,
                          }}
                          to={{
                            translateX: '-30%',
                            translateY: '-85%',
                            scale: 0.2,
                            opacity: 1,
                          }}
                          duration={animationDuration}
                        />
                        <Tween
                          to={{
                            translateX: '-50%',
                            translateY: '-50%',
                            opacity: 0,
                            scale: 0.2,
                          }}
                          delay={animationDelay}
                          duration={animationDuration}
                        />
                      </Timeline>

                      <Timeline
                        target={
                          <img
                            className="fd-paper fd-paper-layer2 fd-paper-0"
                            src={PaperImg3}
                            alt="试卷"
                          />
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{
                            translateX: '-73%',
                            translateY: '-50%',
                            scale: 0.2,
                            opacity: 0,
                          }}
                          to={{
                            translateX: '-73%',
                            translateY: '-50%',
                            scale: 0.2,
                            opacity: 1,
                          }}
                          duration={animationDuration}
                        />
                        <Tween
                          to={{
                            translateX: '-50%',
                            translateY: '-50%',
                            opacity: 0,
                            scale: 0.2,
                          }}
                          delay={animationDelay}
                          duration={animationDuration}
                        />
                      </Timeline>

                      <Timeline
                        target={
                          <img
                            className="fd-paper fd-paper-layer2 fd-paper-1"
                            src={PaperImg4}
                            alt="试卷"
                          />
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{
                            translateX: '-53%',
                            translateY: '-50%',
                            scale: 0.2,
                            opacity: 0,
                          }}
                          to={{
                            translateX: '-53%',
                            translateY: '-50%',
                            scale: 0.2,
                            opacity: 1,
                          }}
                          duration={animationDuration}
                        />
                        <Tween
                          to={{
                            translateX: '-50%',
                            translateY: '-50%',
                            opacity: 0,
                            scale: 0.2,
                          }}
                          delay={animationDelay}
                          duration={animationDuration}
                        />
                      </Timeline>

                      <Timeline
                        target={
                          <img
                            className="fd-paper fd-paper-layer2 fd-paper-2"
                            src={PaperImg5}
                            alt="试卷"
                          />
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{
                            translateX: '-33%',
                            translateY: '-50%',
                            scale: 0.2,
                            opacity: 0,
                          }}
                          to={{
                            translateX: '-33%',
                            translateY: '-50%',
                            scale: 0.2,
                            opacity: 1,
                          }}
                          duration={animationDuration}
                        />
                        <Tween
                          to={{
                            translateX: '-50%',
                            translateY: '-50%',
                            opacity: 0,
                            scale: 0.2,
                          }}
                          delay={animationDelay}
                          duration={animationDuration}
                        />
                      </Timeline>

                      <Timeline
                        target={
                          <img
                            className="fd-paper fd-paper-layer3 fd-paper-0"
                            src={PaperImg6}
                            alt="试卷"
                          />
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{
                            translateX: '-67%',
                            translateY: '-15%',
                            scale: 0.2,
                            opacity: 0,
                          }}
                          to={{
                            translateX: '-67%',
                            translateY: '-15%',
                            scale: 0.2,
                            opacity: 1,
                          }}
                          duration={animationDuration}
                        />
                        <Tween
                          to={{
                            translateX: '-50%',
                            translateY: '-50%',
                            opacity: 0,
                            scale: 0.2,
                          }}
                          delay={animationDelay}
                          duration={animationDuration}
                        />
                      </Timeline>

                      <Timeline
                        target={
                          <img
                            className="fd-paper fd-paper-layer3 fd-paper-1"
                            src={PaperImg7}
                            alt="试卷"
                          />
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{
                            translateX: '-47%',
                            translateY: '-15%',
                            scale: 0.2,
                            opacity: 0,
                          }}
                          to={{
                            translateX: '-47%',
                            translateY: '-15%',
                            scale: 0.2,
                            opacity: 1,
                          }}
                          duration={animationDuration}
                        />
                        <Tween
                          to={{
                            translateX: '-50%',
                            translateY: '-50%',
                            opacity: 0,
                            scale: 0.2,
                          }}
                          delay={animationDelay}
                          duration={animationDuration}
                        />
                      </Timeline>

                      <Timeline
                        target={
                          <img
                            className="fd-paper fd-paper-layer3 fd-paper-2"
                            src={PaperImg8}
                            alt="试卷"
                          />
                        }
                        totalProgress={progress}
                        paused
                      >
                        <Tween
                          from={{
                            translateX: '-27%',
                            translateY: '-15%',
                            scale: 0.2,
                            opacity: 0,
                          }}
                          to={{
                            translateX: '-27%',
                            translateY: '-15%',
                            scale: 0.2,
                            opacity: 1,
                          }}
                          duration={animationDuration}
                        />
                        <Tween
                          to={{
                            translateX: '-50%',
                            translateY: '-50%',
                            opacity: 0,
                            scale: 0.2,
                          }}
                          delay={animationDelay}
                          duration={animationDuration}
                        />
                      </Timeline>
                    </div>
                  </div>
                )}
              </Scene>
            </div>
          </Controller>
        </section>
      )}
    </>
  )
}
