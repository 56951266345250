import React from 'react'
import useScrollComment from '@/utils/useScrollComment'
import system from './assets/system.png'
import systemSm from './assets/systemSm.png'
import chip from './assets/chip.png'
import chipSm from './assets/chipSm.png'
import focus from './assets/focus.png'
import focusSm from './assets/focusSm.png'
import ink from './assets/ink.png'
import inkSm from './assets/inkSm.png'
import pen from './assets/pen.png'
import penSm from './assets/penSm.png'
import source from './assets/source.png'
import sourceSm from './assets/sourceSm.png'
import './reason.less'

export default function Reason({ windowLg }: { windowLg: boolean }) {
  const {
    handleClickComment: handleClickComment4,
    commentIndex: commentIndex4,
  } = useScrollComment({
    id: 'ai',
    windowLg,
  })

  const {
    handleClickComment: handleClickComment5,
    commentIndex: commentIndex5,
  } = useScrollComment({
    id: 'blue-ray',
    windowLg,
  })

  const {
    handleClickComment: handleClickComment6,
    commentIndex: commentIndex6,
  } = useScrollComment({
    id: '4096',
    windowLg,
  })

  return windowLg ? (
    <section className="reason-container-lg">
      {/* <div className='reason-header-lg'>必选理由</div> */}
      <h2>必选理由</h2>
      <div className="reason-cards-lg">
        <div className="reason-col-lg">
          <div
            className="reason-system-lg"
            style={{ backgroundImage: `url(${system})` }}
          >
            <div className="reason-title-lg">
              全场景「以练促学」
              <sup onClick={handleClickComment4} style={{ cursor: 'pointer' }}>
                {commentIndex4}
              </sup>
              <br />
              精准学系统
            </div>
            <div className="reason-content-lg">
              线上线下学习过程智能收录分析，个性化学习路径精准铺设
            </div>
          </div>
          <div
            className="reason-pen-lg"
            style={{ backgroundImage: `url(${pen})` }}
          >
            <div className="reason-title-lg">
              自研Wacom
              <br />
              中小学生专属电磁笔
            </div>
            <div className="reason-content-lg">
              磁吸收纳，4096级压感
              <sup onClick={handleClickComment6} style={{ cursor: 'pointer' }}>
                {commentIndex6}
              </sup>
              ，一圈擦除，原生手写体验
            </div>
          </div>
        </div>
        <div className="reason-col-lg">
          <div
            className="reason-source-lg"
            style={{ backgroundImage: `url(${source})` }}
          >
            <div className="reason-title-lg">
              15亿高品质题库资源
              <br />
              3亿题目讲解视频覆盖
            </div>
            <div className="reason-content-lg">
              猿辅导十余年教研资源沉淀，校内外资源一本贯通
            </div>
          </div>
          <div
            className="reason-focus-lg"
            style={{ backgroundImage: `url(${focus})` }}
          >
            <div className="reason-title-lg">
              封闭式系统
              <br />
              专注力升级
            </div>
            <div className="reason-content-lg">
              沉浸体会学习乐趣，细节激发提升动力
            </div>
          </div>
        </div>
        <div className="reason-col-lg">
          <div
            className="reason-ink-lg"
            style={{ backgroundImage: `url(${ink})` }}
          >
            <div className="reason-title-lg">
              10.3英寸
              <br />
              类纸护眼墨水屏
            </div>
            <div className="reason-content-lg">
              屏幕不发光，纯净0蓝光
              <sup onClick={handleClickComment5} style={{ cursor: 'pointer' }}>
                {commentIndex5}
              </sup>
              ，宛若纸张
            </div>
          </div>
          <div
            className="reason-chip-lg"
            style={{ backgroundImage: `url(${chip})` }}
          >
            <div className="reason-title-lg">
              硬核内芯
              <br />
              超轻超薄
            </div>
            <div className="reason-content-lg">
              高通八核芯片，445g超轻机身，64G超大内存，5G云空间
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : (
    <section className="reason-container-sm">
      {/* <div className='reason-header-sm'>必选理由</div> */}
      <h2>必选理由</h2>
      <div className="reason-cards-sm">
        <div className="reason-col-sm">
          <div
            className="reason-system-sm"
            style={{ backgroundImage: `url(${systemSm})` }}
          >
            <div className="reason-title-sm">
              全场景「以练促学」精准学系统
              <sup onClick={handleClickComment4} style={{ cursor: 'pointer' }}>
                {commentIndex4}
              </sup>
            </div>
            <div className="reason-content-sm">
              线上线下学习过程智能收录分析，个性化学习路径精准铺设
            </div>
          </div>
          <div
            className="reason-pen-sm"
            style={{ backgroundImage: `url(${penSm})` }}
          >
            <div className="reason-title-sm">自研Wacom中小学生专属电磁笔</div>
            <div className="reason-content-sm">
              磁吸收纳，4096级压感
              <sup onClick={handleClickComment6} style={{ cursor: 'pointer' }}>
                {commentIndex6}
              </sup>
              一圈擦除，原生手写体验
            </div>
          </div>
          <div
            className="reason-chip-sm"
            style={{ backgroundImage: `url(${chipSm})` }}
          >
            <div className="reason-title-sm">硬核内芯 超轻超薄</div>
            <div className="reason-content-sm">
              高通八核芯片，445g超轻机身，64G超大内存，5G云空间
            </div>
          </div>
        </div>
        <div className="reason-col-sm">
          <div
            className="reason-source-sm"
            style={{ backgroundImage: `url(${sourceSm})` }}
          >
            <div className="reason-title-sm">
              15亿高品质题库资源 3亿题目讲解视频覆盖
            </div>
            <div className="reason-content-sm">
              猿辅导十余年教研资源沉淀，校内外资源一本贯通
            </div>
          </div>
          <div
            className="reason-ink-sm"
            style={{ backgroundImage: `url(${inkSm})` }}
          >
            <div className="reason-title-sm">
              10.3英寸
              <br />
              类纸护眼墨水屏
            </div>
            <div className="reason-content-sm">
              屏幕不发光，纯净0蓝光
              <sup onClick={handleClickComment5} style={{ cursor: 'pointer' }}>
                {commentIndex5}
              </sup>
              宛若纸张
            </div>
          </div>
          <div
            className="reason-focus-sm"
            style={{ backgroundImage: `url(${focusSm})` }}
          >
            <div className="reason-title-sm">
              封闭式系统
              <br />
              专注力升级
            </div>
            <div className="reason-content-sm">
              沉浸体会学习乐趣，细节激发提升动力
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
